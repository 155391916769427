import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import Logo1 from '../../assets/costranslogo.png'
import Logo2 from '../../assets/costranslogo2.png'


export default function Dashboard(props) {

  const [open, setOpen] = useState(null)
  const {course_id, sections, fetchCourseMaterial, logout, currentUser} = props
  const [selectedSection, setSelectedSection] = useState(null)
  const [assignment, setAssignment] = useState(null)
  const [links, setLinks] = useState(null)

  const [profile, setProfile] = useState(null)

  function openProfile() {
      profile ? setProfile(null) : setProfile(true)
  }

  function setOpenSection(num) {
    if (open === num) {
      setOpen(null)
    } else {
      setOpen(num)
    }
  }

  useEffect(() => {
    if (!sections)
   fetchCourseMaterial("6180688eaaf5bcf980232032")
}, [sections])

function setSelection(key) {
  if (selectedSection === key) { setSelectedSection(null) } 
  else {setSelectedSection(key)}
  window.scrollTo(0, 0)
}

function setAssignmentOpen(key) {
  if (assignment === key) { setAssignment(null) } 
  else {setAssignment(key)}
}

function setLinksOpen(key) {
  if (links === key) { setLinks(null) } 
  else {setLinks(key)}
}
  

    return (
      <div className="fade-in">
                <div onClick={openProfile} className="hamburger"><a><i class="fal fa-user"></i></a></div>
        <div className={profile ? "profile" : "hidden"}>
        <div>
            {currentUser ? currentUser.first_name + ' ' + currentUser.last_name : null}
        </div>
        <br/>
        <div onClick={logout}>
        <i  class="fal fa-sign-out"></i> Sign Out
        </div>
        </div>

          <img className="logo-header" src={Logo2}></img>

        <div className="entry-text fade-in">
                    <div className="entry-text-o down-60 no-abs">The Microblading<br/>Full Introductory Course</div>
                    

        <div className="course-sections">
        {sections ? Object.keys(sections).map((key) => {
          var title = sections[key].title
          var reading = sections[key].reading
          var section = sections[key]
          return (
            <>
            <div onClick={() => setSelection(key)} className="entry-text-p course-section-title wht">{title}</div>

            <div className={selectedSection === key ? "course-body fade-in wht" : "hidden"}>
            {section.video1 ? 
                        <>
                                <h2 className="entry-text-p course-section-title wht c">
                                {section.video1Title}
                                </h2>
                                <video className="course" width="100%" poster={section.poster} controls src={section.video1} paused={selectedSection !== key} type="video/mp4" /> 
                        </>  : null}
              <div className="width-90">
              <div className="course-reading">{reading ? reading : null}</div>
    
              {section.header1 ? <h1 className="header">{section.header1}</h1> : null}
              {section.text1 ? section.text1.replace(/\\n/g, '\n') : null}
              {section.image1 ? <img src={section.image1}/> : null}
              {section.header2 ? <h1 className="header">{section.header2}</h1>  : null}
              {section.text2 ? section.text2.replace(/\\n/g, '\n').replace(/\\b/g, <br/>) : null}
              {section.image2 ? <img src={section.image2}/> : null}
              {section.header3 ? <h1 className="header">{section.header3}</h1>  : null}
              {section.text3 ? section.text3.replace(/\\n/g, '\n').replace(/\\b/g, '\n') : null}
              {section.image3 ? <img src={section.image3}/> : null}
              {section.header4 ? <h1 className="header">{section.header4}</h1>  : null}
              {section.text4 ? section.text4.replace(/\\n/g, '\n') : null}
              {section.image4 ? <img src={section.image4}/> : null}
              {section.header5 ? <h1 className="header">{section.header5}</h1>  : null}
              {section.text5 ? section.text5.replace(/\\n/g, '\n') : null}
              {section.image5 ? <img src={section.image5}/> : null}
              {section.header6 ? <h1 className="header">{section.header6}</h1>  : null}
              {section.text6 ? section.text6.replace(/\\n/g, '\n') : null}
              {section.image6 ? <img src={section.image6}/>: null}
              </div>

              {section.video2 ? 
                        <>
                                <h2 className="entry-text-p course-section-title wht c">
                                {section.video2Title}
                                </h2>
                                <video className="course" width="100%" poster={section.poster} controls src={section.video2} paused={selectedSection !== key} type="video/mp4" /> 
                        </>  : null}
                              
  
              {section.assignmentDesc ? 
              <div className="assignment">
              <div onClick={() => setAssignmentOpen(key)} className="assignment-title">Assignment</div><span className="plus">{assignment === key ? `-` : `+`}</span>
              <div className={assignment === key ? "assignment-desc" : "hidden"}>{section.assignmentDesc}
              <br/>
              <a className="assignment" href={`mailto:cosmetinkbeauty@gmail.com?subject=${section.emailSubject}&body=${section.emailBody}`}>Submit Assignment</a>
              <br/>
             
              {section.link1 ? 
                  <>
              <div className="course-section-title blk">Important Links</div>
            
          
  

          
              {section.link1 ?
     
              <>
          
              {section.link1title}<br/>
              <a href={section.link1}>{section.link1}</a>
              </> : null}
              {section.link2 ? <>
              {section.link2title}<br/>
              <a href={section.link2}>{section.link2}</a>
              </> : null}
              {section.link3 ? <>
              {section.link3title}<br/>
              <a href={section.link3}>{section.link3}</a>
              </> : null}
  
         
   
            </> : null }
            </div>

              </div>: null }


              {!section.assignmentDesc && section.link1 ? 
              <div className="assignment">
              <div onClick={() => setAssignmentOpen(key)} className="assignment-title">Link</div><span className="plus">{assignment === key ? `-` : `+`}</span>
              <div className={assignment === key ? "assignment-desc" : "hidden"}>{section.assignmentDesc}
    
              <div className="course-section-title blk">Important Links</div>
              {section.link1 ? 
              <>
  

          
              {section.link1 ?
     
              <>
          
              {section.link1title}<br/>
              <a href={section.link1}>{section.link1}</a>
              </> : null}
              {section.link2 ? <>
              {section.link2title}<br/>
              <a href={section.link2}>{section.link2}</a>
              </> : null}
              {section.link3 ? <>
              {section.link3title}<br/>
              <a href={section.link3}>{section.link3}</a>
              </> : null}
  
         
   
            </> : null }
            </div>

              </div>: null }

            </div>
      
   
            </>
        )
        }) : null}
 

        </div>

        </div>
        
        
        
        </div>
        );
    }